import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import LoginPage from "./components/LoginPage";
import Homepage from "./components/Homepage";
import PrivateRoute from "./components/PrivateRoute";
import ForgotPasswordPage from "./components/ForgotPasswordPage";
import ResetPasswordPage from "./components/ResetPasswordPage";
import NewUser from "./components/NewUser";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [isIdle, setIsIdle] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    // Function to update desktop status
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const storedLoggedInStatus = sessionStorage.getItem("isLoggedIn");
    setIsLoggedIn(storedLoggedInStatus === "true");
  }, []);

  useEffect(() => {
    // Load the Google Analytics script
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-FE7EF5N0KQ`;
    document.head.appendChild(script);

    // Initialize Google Analytics
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-FE7EF5N0KQ");
    };
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
    sessionStorage.setItem("isLoggedIn", "true");
    setShowWarning(false);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    sessionStorage.removeItem("isLoggedIn");
    localStorage.removeItem("token");
    setShowWarning(false);
    // Additional cleanup logic if needed
  };

  const onIdle = () => {
    if (isLoggedIn) {
      setIsIdle(true);
    }
  };

  const onActive = () => {
    if (isIdle) {
      setIsIdle(false); // Reset idle state when the user becomes active again
      setShowWarning(false); // Clear warning if user is active
    }
  };

  const onAction = () => {
    if (isIdle) {
      setIsIdle(false); // Reset idle state on any user action
      setShowWarning(false); // Clear warning if user interacts
    }
  };

  const idleTimer = useIdleTimer({
    timeout: 1000 * 60 * 10, // 10 minutes
    onIdle: onIdle,
    onActive: onActive,
    onAction: onAction,
    debounce: 250,
    events: ["mousemove", "keydown", "wheel", "touchmove", "pointermove"], // Add more events as needed
  });

  useEffect(() => {
    if (isIdle) {
      const warningTimeout = setTimeout(() => {
        setShowWarning(true); // Show warning 1 minute before logout
      }, 1000 * 60 * 9); // 9 minutes after idle

      const logoutTimeout = setTimeout(() => {
        //handleLogout();
        //alert("You have been logged out due to inactivity.");
      }, 1000 * 60); // 1 minute after idle warning

      return () => {
        clearTimeout(warningTimeout);
        clearTimeout(logoutTimeout);
      };
    }
  }, [isIdle]);

  if (!isDesktop) {
    return (
      <div style={{ textAlign: "center", padding: "50px", color: "#fff" }}>
        <h2>This application is only available on desktop resolutions.</h2>
      </div>
    );
  }

  return (
    <Router>
      <div className="app-container">
        <Routes>
          <Route
            path="/"
            element={
              isLoggedIn ? (
                <Navigate to="/home" replace />
              ) : (
                <LoginPage handleLogin={handleLogin} />
              )
            }
          />
          <Route
            path="/home"
            element={
              <PrivateRoute isLoggedIn={isLoggedIn}>
                <Homepage isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
              </PrivateRoute>
            }
          />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/user-registration" element={<NewUser />} />
          <Route
            path="*"
            element={<Navigate to={isLoggedIn ? "/home" : "/"} replace />}
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
