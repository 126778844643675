import React, { useEffect, useState, useRef } from "react";
import "./Menu.css";

const Menu = ({ handleLogout, username, externalToggle }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null); // Ref for the menu container

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Effect to listen for changes in externalToggle and open/close the menu accordingly
  useEffect(() => {
    setIsOpen(externalToggle);
  }, [externalToggle]);

  // Effect to close the menu if clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]); // Depend on isOpen to add/remove the event listener

  return (
    <div className="menu" ref={menuRef}>
      <button className={`icon ${isOpen ? "open" : ""}`} onClick={toggleMenu}>
        <span className="name">{username}</span>{" "}
        <span className={`arrow ${isOpen ? "up" : "down"}`}>&#9660;</span>{" "}
        {/* Down arrow symbol */}
      </button>
      {isOpen && (
        <div className="menu-content">
          <a
            href="https://www.dynamiq.com/contact"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button onClick={() => console.log("Profile clicked")}>
              Contact us
            </button>
          </a>
          <button onClick={handleLogout}>Log out</button>
        </div>
      )}
    </div>
  );
};

export default Menu;
